a {
    color: inherit;

}
.navLink {
    color: inherit;
    text-decoration: none;
}

.dropdown > a {
    padding: 0
}
